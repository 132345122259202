.popupContainer{
    height: 100vh;
    width: 110vw;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(5.5px);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popupWrapper{
    min-height: 50vh;
    max-height: 90vh;
    min-width: 60vw;
    max-width: 80vw;
    transition: all 0.5s ease-in-out, color 0s ease-in-out;
    background-color: var(--dark-mode);
    border: 1px solid var(--dark-20percent-mode);
    box-shadow: 0px 2px 5px 2px var(--dark-20percent-mode);
}
.popupHeaderFlex{
    background-color: var(--dark-mode);
    margin: 10px;
    margin-bottom: 0px;
    padding-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 3px solid var(--light-50percent-mode);
}

.popupBody{
    background-color: white;
    padding: 20px;
}