.scriptRevenueContainer{
    /* margin: 20px; */
    height: 100vh;
    position: relative;
    overflow: auto;
}
.heading{
    font-size: 32px;
    font-family: 800;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
}

.selectPending{
    outline: none  !important;
    border: 0px solid black  !important;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 19px;
    font-weight: 500;
    margin-left: 2px;
}

.apexcharts-svg{
    /* background-color: var(--dark-mode) !important; */
    transition: all 0.5s ease-in-out !important;
}
.menuContainerCombine{
    position: fixed;
    margin-top: -42.5px;
    background-color: rgba(0, 0, 0, 0);
    color:white;
    margin-left: 6px;
    z-index: 100000;
    padding: 4px;
    border-radius: 0.5px;
    border-color: (--ambient-color-90-percent-lighter);
    background-color: var(--ambient-color-80-percent-lighter);
    /* box-shadow: 0 0.1px 0.1px rgba(28, 10, 10, 0.2); */
}

.menuItem{
    padding: 12px;
    cursor: pointer;
    font-size: 12px;
    color: black;
    background-color: white;
}

.menuItem:hover{
    background-color: var(--ambient-color);
}